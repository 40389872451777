
.App {
 @apply  min-h-screen md:flex bg-black
        
}


.App-Nav{
  @apply min-h-screen md:flex
}

.App-main {
  @apply flex-1 grid
  bg-slate-600 h-screen 
  
  
}