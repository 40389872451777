@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {

    .welcome{
         @apply flex items-center justify-center
    }
    
    
    .welcome-box{
        
        @apply justify-center items-center 
        border-2 border-gray-400 
        p-14
    }


    .welcome-title{
        @apply font-bold font-sans text-5xl text-stone-300
    }




}