@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {



    .info-box{
        @apply m-2 p-4 w-40
        border border-gray-500 rounded-lg
        bg-slate-400 
    }


    .title{
        @apply text-center font-bold 
    }


    .info-stuff{
        @apply font-bold text-center pt-2
    }
}

