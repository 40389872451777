@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {


    .navbar{
      @apply 
            bg-slate-500 w-60 min-h-screen md:flex
    }


    .links{
        @apply pt-16
    }






}

