@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {

.side-nav-card{
    @apply  font-bold 
}

.side-nav-card a{
    @apply p-2 flex align-middle items-center w-full
    hover:bg-slate-900 rounded-none 
    hover:rounded-none transition-all duration-300 ease-linear
    cursor-pointer 
    hover:text-gray-400;

}
.side-nav-card img{
    @apply inline-block h-10 w-12 rounded-full flex-shrink 
}

.side-nav-card .card-info{
    @apply whitespace-nowrap overflow-hidden  w-full  justify-between  text-ellipsis flex
}

.side-nav-card .card-metadata{
    @apply text-ellipsis whitespace-nowrap overflow-hidden  ml-4 w-full text-sm
}

.side-nav-card .card-title{
    @apply flex align-middle items-center
}

.side-nav-card .card-game-title{
    @apply pr-2
}

.side-nav-card .card-status{
    @apply flex  ml-2
}




}