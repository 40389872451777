@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {

    .meun-item{
        @apply block px-4 py-2 
        hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white
    }



    .dropdown-div{
        @apply flex-initial m-3 items-center relative 
        hover:border border-gray-50 rounded-md
    }


    .dropdown{
        @apply justify-center w-full rounded-md  px-4 py-2 
        bg-gray-800 text-sm font-medium text-stone-50 
         
    }


}